.sidebar {
  padding: 10px 16px;
  border: solid 2px var(--primaryColor);
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
  width: 300px;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  transition: all 0.5s;
  border-radius: 8px;
  top: 16px;
  left: 16px;
  height: var(--selectAreaHeight);

  .select-area {
  }

  .header {
    padding: 0 4px 8px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: left;
    border-bottom: solid 1px var(--primaryColor);
    height: var(--headerHeight);
    box-sizing: border-box;

    h2 {
      color: var(--primaryColor);
      padding: 0;
      margin: 0 8px;
      font-size: 18px;
      line-height: 1;
    }
  }

  .title {
    padding: 10px 0;
    border-bottom: solid 1px var(--primaryColor);
    height: var(--titleHeight);
    box-sizing: border-box;
    margin-bottom: 8px;

    h2 {
      margin: 0;
      text-align: center;
      line-height: 1;
      color: var(--primaryColor);
      font-size: 20px;
    }
  }

  .button-container {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: right;

    button {
      background: #ffe6e6;
      border: 1px solid var(--primaryColor);
      padding: 4px 8px;
      border-radius: 3px;
      color: var(--primaryColor);
      margin-right: 4px;
    }
  }

  .note {
    background-color: var(--red-1);
    padding: 8px 12px;
    border-radius: 4px;

    p {
      margin: 0;
      line-height: 1.5;
      font-size: 14px;
      color: var(--brown-0);
    }
  }

  .sidebar-item-container {
    border: solid 1px var(--gray-1);
    margin-bottom: 10px;
    max-height: 200px;
    // overflow-y: scroll;
    border-radius: 4px;
    padding: 2px 0;

    .sidebar-item {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      padding: 4px;
      border-bottom: solid 1px var(--gray-1);
      cursor: pointer;

      &:last-child {
        border: none;
      }

      .label {
        padding: 0px;
        color: var(--brown-0);

        input {
          margin-right: 8px;
        }
      }

      .category-toggle {
        border: 0;
        background: none;
        margin-left: -2em;
      }
    }
  }

  .category-label {
    font-weight: bold;
  }

  .sidebar-item-category-items {
    padding-left: 1em;
  }

  .detail-wrapper {
    max-height: calc(100% - var(--selectAreaHeight) - 16px);
    overflow: scroll;
  }

  .user-guide-link {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    padding: 5px 0;
    border-bottom: 1px solid #E9E9E9;
    cursor: pointer;
    text-decoration: none;
    color: #000000;

    svg {
      margin-right: 5px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {

    div.sidebar.sidebar-open {
      height: 460px;

      #list-close {
        display: flex;
      }
    }

    div.sidebar {
      height: 64px;
      position: absolute;
      top: auto;
      bottom: -4px;
      border-radius: 8px 8px 0 0;
      left: 0;
      border-bottom: none;
      z-index: 3;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      padding: 32px 16px;
      width: 100%;

      &::before {
        background-color: rgba(32, 32, 32, .2);
        border-radius: 4px;
        content: "";
        display: block;
        height: 5px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 8px;
        width: 40px;
      }

      .title {
        margin: 0 0 16px 0;
        display: flex;
        align-items: center;

        h2 {
          width: 100%;
        }

        .list-icon {
          display: block;
          margin-right: 5px;
          height: 20px;
          width: 20px;
        }
      }

      #list-close {
        height: 15px;
        width: 15px;
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 100;
        position: absolute;
        top: 28px;
        right: 20px;
        padding: 5px;
        cursor: pointer;
      }

      #list-close span,
      #list-close span:before,
      #list-close span:after {
        content: '';
        display: block;
        height: 2px;
        width: 18px;
        border-radius: 3px;
        background-color: #000000;
        position: absolute;
      }

      #list-close span:before {
        bottom: 0;
        transform: rotate(45deg);
      }

      #list-close span:after {
        top: 0;
        transform: rotate(-45deg);
      }

      #list-close span {
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }
  .note a {
    font-size: 14px;
  }
}
