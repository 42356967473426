:root {
  --primaryColor: #ff3333;
  --secondaryColor: #f6a7aa;
  --gray-0: #F0F0EF;
  --gray-1: #e6e6e6;
  --red-0: #ffe6e6;
  --red-1: #f6e4e4;
  --red-2: #ff6347;

  --white: #fff;

  --brown-0: #66423e;

  --titleHeight: 42px;
  --headerHeight: 28px;
  --selectAreaHeight: 406px;
}

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
  font-size: 16px;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;

  .map-container {
    position: relative;
    flex-grow: 1;
    width: auto;
    height: 100%;
    box-sizing: border-box;

    .map {
      width: 100%;
      height: 100%;
    }
  }
}

.margin-top-small {
  margin-top: 8px;
}
