:root {
  --detailHeight: calc(100% - 406px - 40px)
}

.sidebar-detail {
  padding: 12px;
  position: absolute;
  z-index: 10;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
  left: 16px;
  top: calc(100% - var(--detailHeight) - 16px);
  width: 300px;
  box-sizing: border-box;
  color: #333;
  height: var(--detailHeight);
  border: solid 2px var(--primaryColor);
  border-radius: 8px;
  box-sizing: border-box;

  .inner {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .sidebar-detail-inner {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
  }

  // .sidebar-detail-subsection {
  // }

  hr {
    background-color: rgba(0,0,0,0);
    border: none;
    border-bottom: dotted 2px var(--red-2);
  }

  .close-btn-container {
    position: absolute;
    right: 0;

    .close {
      cursor: pointer;
      font-size: 24px;
      position: absolute;
      right: 0;
      color: var(--brown-0);
    }
  }

  .category {
    font-size: 14px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--primaryColor);
    color: var(--primaryColor);
    padding: 4px;
    margin: 0;
  }

  .title {
    border-bottom: solid 1px var(--primaryColor);
    padding: 8px 4px;
    margin-bottom: 8px;

    h3 {
      margin: 0;
      color: var(--primaryColor);
      font-size: 16px;
    }
  }

  .btnWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 4px 8px 12px;
    border-bottom: var(--red-2) solid 1px;

    .btn {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      .icon {
        height: 40px;
        width: 40px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px var(--red-2);
        border-radius: 100px;
        color: var(--red-2);
        transition: all 0.5s;
      }
      &:hover {
        .icon {
        background-color: var(--red-0);
        transition: all 0.5s;
        }
      }
      .btn-text {
        font-size: 12px;
        color: var(--red-2);
        text-align: center;
        margin: 4px 0 0;
        font-weight: bold;
      }
    }
  }

  .sidebar-detail-single-feature {
    width: 100%;
    table-layout: fixed;
    border-collapse:collapse;

    col.label {
      width: 30%;
    }
    col.content {
      width: 70%;
    }
  }

  .sidebar-detail-item {
    border-bottom: 1px solid var(--gray-1);

    th, td {
      padding: 3px;
    }

    th.label {
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      overflow-wrap: break-word;
      color: var(--brown-0);
      font-weight: 600;
      background-color: var(--red-1);
      padding: 4px 8px;
      box-sizing: border-box;
      width: 40%;
      border-bottom: 1px solid var(--white);
    }
    td.content {
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: var(--brown-0);
      overflow-wrap: break-word;
      padding: 4px 8px;
      border-bottom: 1px solid var(--red-1);
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .sidebar-detail {
      left: 2.5%;
      width: 95%;
      bottom: 68px;
      top: auto;
      border-radius: 16px;
    }
  }
}
